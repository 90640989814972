import _defineProperty from "/Users/aa/Desktop/gdcomm/fourpaws/Fourpaws-viewer/node_modules/@babel/runtime/helpers/esm/defineProperty.js"; // 각 스테이터스 별로 loading: 로딩중인지, done: 처리가 끝났는지,
// error: 에러가 있는지 (에러가 있으면 에러메세지로 설정)을 확인할 수 있다
export var createStatus = function createStatus() { return { loading: false, done: false, error: null }; };
export var resetStatus = function resetStatus() { return { loading: false, done: false, error: null }; };
export var createRequestStatus = function createRequestStatus() { return { loading: true, error: null, done: false }; };
export var createSuccessStatus = function createSuccessStatus() { return { loading: false, error: null, done: true }; };
export var createFailureStatus = function createFailureStatus(error) { return { loading: false, done: false, error: error }; }; // 액션 타입을 만들어주는 함수
// 액션 string은 getLetters 처럼 camelCase로 넣어주면
export var createActionType = function createActionType(store, action, isAsync) {
    var _ref; // 대문자 스네이크 케이스인지 확인
    var isSnakeCase = action.toUpperCase() === action;
    if (!isSnakeCase) {
        console.error('action type must be snake case');
    }
    return isAsync ? (_ref = {}, _defineProperty(_ref, "".concat(action, "_REQUEST"), "".concat(store, "/").concat(action, "_REQUEST")), _defineProperty(_ref, "".concat(action, "_SUCCESS"), "".concat(store, "/").concat(action, "_SUCCESS")), _defineProperty(_ref, "".concat(action, "_FAILURE"), "".concat(store, "/").concat(action, "_FAILURE")), _ref) : _defineProperty({}, "".concat(action), "".concat(store, "/").concat(action));
};
