import _toConsumableArray from "/Users/aa/Desktop/gdcomm/fourpaws/Fourpaws-viewer/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ENABLE_REDUX_DEV_TOOLS } from "../constants";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
var sagaMiddleware = createSagaMiddleware();
var store = configureStore({ reducer: rootReducer, devTools: ENABLE_REDUX_DEV_TOOLS, middleware: [].concat(_toConsumableArray(getDefaultMiddleware()), [sagaMiddleware]) });
sagaMiddleware.run(rootSaga);
export var useSelector = useReduxSelector;
export var useDispatch = function useDispatch() { return useReduxDispatch(); };
export default store;
