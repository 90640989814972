import _objectSpread from "/Users/aa/Desktop/gdcomm/fourpaws/Fourpaws-viewer/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { enableAllPlugins } from 'immer';
import { produce } from 'immer';
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, createActionType } from '../utils/reducerUtils';
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { letters: [], currentLetter: { letterId: '', exhibitionId: '', exhibitionTitle: '', uid: '', isDeleted: false, isSent: false, from: 'owner', sentAt: null, viewedAt: null, createdAt: null, updatedAt: null, deletedAt: null, likes: { total: 0 }, views: { total: 0 }, imageLink: '',
        message: '',
        isPublic: false, isOwnerViewed: false }, letterModalType: '', getLetterStatus: createStatus(), getLettersStatus: createStatus(), updateLetterStatus: createStatus(), sendLetterStatus: createStatus() }; // 액션들의 집합.
export var actions = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, createActionType('letter', 'GET_LETTER', true)), createActionType('letter', 'GET_LETTERS', true)), createActionType('letter', 'SET_LETTER', false)), createActionType('letter', 'SET_LETTERS', false)), createActionType('letter', 'UPDATE_LETTER', true)), createActionType('letter', 'SEND_LETTER', true)), createActionType('letter', 'SET_LETTER_MODAL_TYPE', false)); // 액션 크리에이터. dispatch 내부에서 사용.
export var getLettersAction = function getLettersAction() { return { type: actions.GET_LETTERS_REQUEST }; };
export var getLetterAction = function getLetterAction(letterId) { return { type: actions.GET_LETTER_REQUEST, payload: letterId }; };
export var setLetterAction = function setLetterAction(letter) { return { type: actions.SET_LETTER, payload: letter }; };
export var setLettersAction = function setLettersAction(letters) { return { type: actions.SET_LETTERS, payload: letters }; };
export var updateLetterAction = function updateLetterAction(letter) { return { type: actions.UPDATE_LETTER_REQUEST, payload: letter }; };
export var sendLetterAction = function sendLetterAction(exhibitionId, uid, exhibitionTitle, message) { return { type: actions.SEND_LETTER_REQUEST, payload: { exhibitionId: exhibitionId, uid: uid, exhibitionTitle: exhibitionTitle, message: message } }; };
export var setLetterModalTypeAction = function setLetterModalTypeAction(type) { return { type: actions.SET_LETTER_MODAL_TYPE, payload: type }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, function (draft) { switch (action.type) {
    case actions.GET_LETTER_REQUEST:
        draft.getLetterStatus = createRequestStatus();
        break;
    case actions.GET_LETTER_SUCCESS:
        draft.getLetterStatus = createSuccessStatus();
        draft.currentLetter = action.payload;
        break;
    case actions.GET_LETTER_FAILURE:
        draft.getLetterStatus = createFailureStatus(action.payload);
        break;
    case actions.GET_LETTERS_REQUEST:
        draft.getLettersStatus = createRequestStatus();
        break;
    case actions.GET_LETTERS_SUCCESS:
        draft.getLettersStatus = createSuccessStatus();
        draft.letters = action.payload;
        break;
    case actions.GET_LETTERS_FAILURE:
        draft.getLettersStatus = createFailureStatus(action.payload);
        break;
    case actions.SET_LETTER:
        draft.currentLetter = action.payload;
        break;
    case actions.SET_LETTERS:
        draft.letters = action.payload;
        break;
    case actions.UPDATE_LETTER_REQUEST:
        draft.getLetterStatus = createRequestStatus();
        break;
    case actions.UPDATE_LETTER_SUCCESS:
        draft.getLetterStatus = createSuccessStatus();
        draft.currentLetter = action.payload;
        break;
    case actions.UPDATE_LETTER_FAILURE:
        draft.getLetterStatus = createFailureStatus(action.payload);
        break;
    case actions.SEND_LETTER_REQUEST:
        draft.sendLetterStatus = createRequestStatus();
        break;
    case actions.SEND_LETTER_SUCCESS:
        draft.sendLetterStatus = createSuccessStatus();
        break;
    case actions.SEND_LETTER_FAILURE:
        draft.sendLetterStatus = createFailureStatus(action.payload);
        break;
    case actions.SET_LETTER_MODAL_TYPE:
        draft.letterModalType = action.payload;
        break;
    default: break;
} }); };
export default reducer;
